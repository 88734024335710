// Build information, automatically generated by `ng-info`
const build = {
    version: "0.0.0",
    timestamp: "Thu Feb 27 2025 15:11:16 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "staging",
        hash: "1b7466",
        fullHash: "1b746630125698635434601a199c508346f12c1a"
    }
};

export default build;